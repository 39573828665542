<template>
  <nav class="nav">
    <ul class="nav__list">
      <li class="nav__item">
        <input type="date" />
      </li>
      <li>
      </li>
    </ul>
  </nav>
</template>

<script>
import Reports from "@/helpers/reports";

export default {
  name: "Datemenu",
  data() {
    return {
      channel: null,
      authenticated: window.localStorage.getItem("token"),
      reportSite: "",
    };
  },
  computed: {
    reports() {
      return Reports.reports;
    },
    available_reports() {
      const keys = Object.keys(Reports.reports);
      return keys;
    },
  },
  methods: {},
  watch: {
    reportSite: function (val) {
      this.$store.dispatch("setReportSite", val);
    },
  },
};
</script>

<style scoped lang="scss">
a {
  display: block;
  cursor: pointer;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.nav {
  &__list {
    background: white;
    padding: 0rem;
    flex: 1 1;
    height: 100vh;
    background-color: var(--tertiary-colour);
    position: fixed;
    left: 17rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
  }
  &__item {
    list-style: none;
    font-size: 0.8rem;
    text-align: right;
    transition: all 200ms ease-in;
    padding: 0.5rem 1rem 0.5rem 1rem;
    &:hover {
      background-color: var(--banner-colour);
    }

    a {
      color: var(--text-colour);
      text-decoration: none;

      &:hover {
        color: var(--action-colour);
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .nav {
    &__list {
      flex-direction: row;
      width: 100vw;
      height: 3rem;
      bottom: 8rem;
      left: 0rem;
      overflow-y: hidden;
      align-items: center;
      justify-content: space-around;
      padding: 0 1rem;
    }

    &__item {
      text-align: center;
      font-size: 1rem;
      padding-top: 0.8rem;
      padding-bottom: 1rem;

      input {
        width: 100%;
      }
    }

    &__list.active {
      top: 0px;
      height: calc(100vh - 5rem);
      overflow-y: auto;
    }
  }
}
</style>
