<template>
  <Authenticated>
    <div class="dashboard-parent">
      <section class="tabs">
        <ul>
          <li @click.stop.prevent="siteAll" class="all" tabindex="0">all</li>
          <li v-for="(site, idx) in siteMap" :tabindex="idx" :key="site" @click.stop.prevent="siteTabSelect(site)">{{ site.site }}</li>
        </ul>
      </section>

      <div class="dashboard-grid">
        <div class="section-one square">
          <div class="inner">
            <EditDash class="edit-icon" @click.stop.prevent="editCurrentDash" />
            <div class="title">
              <ReportsComponent dataType="sales" :site="site" :day="day" :otherDay="otherDay" :period="'today'" class="scaleDown" />
            </div>
          </div>
        </div>

        <div class="section-two square">
          <div class="inner">
            <EditDash class="edit-icon" />
            <div class="title">
              <ReportsComponent dataType="count" :site="site" :day="day" :otherDay="otherDay" :period="'today'" class="scaleDown" />
            </div>
          </div>
        </div>

        <div class="section-three square">
          <div class="inner">
            <EditDash class="edit-icon" />
          </div>
        </div>

        <div class="section-four square">
          <div class="inner">
            <EditDash class="edit-icon" />
          </div>
        </div>

        <div class="section-five square">
          <div class="inner">
            <EditDash class="edit-icon" />
            <div v-for="(site, index) in active" :key="site + index">
              {{ site.site }}

              <ul v-for="(cat, index) in Object.keys(site.stations)" :key="cat + index">
                {{
                  cat
                }}
                <template v-for="(station, index) in site.stations[cat]" :key="station + index">
                  <li v-if="station.active">
                    {{ station.name }}
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Authenticated>
</template>

<script>
import Authenticated from "@/components/_layouts/Authenticated";
import createdMixin from "@/components/_mixins/createdMixin";
import { mapGetters } from "vuex";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import dayOfYear from "dayjs/plugin/dayOfYear";
import { dayRange } from "@/helpers/time";
import ReportsComponent from "@/components/Reports/ReportsComponent";
import EditDash from "@/components/Dashboard/EditDash";
// import BarChart from "@/components/Reports/BarChart";

dayjs.extend(utc);
dayjs.extend(dayOfYear);

export default {
  name: "app",
  mixins: [createdMixin],
  components: { Authenticated, ReportsComponent, EditDash },
  emits: ["handleMenu"],

  data() {
    return {
      site: this.$route.params && this.$route.params.site ? this.$route.params.site : "all",
      day: dayRange.getToday(),
      otherDay: dayRange.getLastWeekDay(),
      dataType: "",
      period: "",
      edit: false,
    };
  },
  computed: {
    ...mapGetters({
      reports: "reports",
      siteSettings: "siteSettings",
      siteMap: "siteMap",
    }),

    active() {
      return this.siteMap.filter((site) => site);
    },
  },
  methods: {
    editCurrentDash() {
      this.edit = !this.edit;
      // console.log(this.edit);
    },
    siteTabSelect(site) {
      this.site = site.site;
    },
    siteAll() {
      this.site = "all";
      let selectedTab = document.querySelector(".all");
      selectedTab.focus();
    },
  },
  watch: {
    site: function() {
      this.$router.replace({ path: "/dashboard/" + this.site });
      if (this.site === "all") {
        let selectedTab = document.querySelector(".all");
        selectedTab.focus();
      }
    },
  },
  mounted() {
    console.log(this.site);
    if (this.site === "all") {
      let selectedTab = document.querySelector(".all");
      selectedTab.focus();
    }
  },
};
</script>

<style scoped lang="scss">
.dashboard-parent {
  height: 100vh;
  width: calc(100vw-5rem);
  overflow: hidden;
  background-color: var(--primary-colour);
  padding: 0;
  box-sizing: border-box;

  .tabs {
    position: fixed;
    background-color: var(--secondary-colour);
    height: calc(100vh - 2.35rem);
    width: calc(100vw - 5.4rem);
    right: 0.15rem;
    top: 2.15rem;
    display: flex;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
    text-align: center;
    color: var(--text-colour);
    z-index: 0;
    border-radius: 7.5px;
    ul {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      .all:focus {
        color: var(--action-colour);
        box-shadow: 0 -5px 5px 0 var(--primary-colour);
        z-index: 100;
        transition: all 1000ms ease-in-out;
      }
      li {
        width: 7rem;
        position: fixed;
        top: 0;
        list-style: none;
        margin: 0.25rem;
        padding: 0.5rem 1rem;
        font-size: 0.8rem;
        border-radius: 15px 15px 0 0;
        background-color: var(--secondary-colour);
        z-index: 10;
        transition: all 1000ms ease-in-out;
        text-transform: capitalize;
        &:hover {
          cursor: pointer;
        }
        &:focus {
          color: var(--action-colour);
          box-shadow: 0 -5px 10px 0 var(--primary-colour);
          z-index: 100;
          transition: all 1000ms ease-in-out;
        }
        @for $i from 1 through 20 {
          &:nth-child(#{$i}) {
            left: (9rem * $i);
          }
        }
      }
    }
  }

  .store-nav {
    height: 2rem;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    padding: 0;
    text-align: center;
    color: var(--text-colour);

    li {
      list-style: none;
      margin: 0 1rem;
      padding: 0;
      font-size: 1rem;
      font-weight: bolder;
      &:hover {
        cursor: pointer;
        color: var(--action-colour);
      }
    }
  }

  .dashboard-grid {
    padding: 0.5rem 0 0 0;
    position: relative;
    height: calc(100vh - 2rem);
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    top: 2rem;
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    color: var(--text-colour);
    z-index: 100;
    margin: 2rem, 0.25rem 0.25rem 0.25rem;
    .square {
      padding: 0.5rem;
      overflow-y: scroll;
      .inner {
        position: relative;
        height: 100%;
        width: 100%;
        background-color: var(--primary-colour);
        box-shadow: var(--box-shadow);
        border-radius: 5px;
        text-transform: capitalize;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        overflow-y: scroll;
        .edit-icon {
          position: absolute;
          right: 0;
          display: inherit;
          margin-left: auto;
          z-index: 0;
          padding: 0.25rem;
          opacity: 0.3;
        }
        div {
          padding: 1rem;
          ul {
            color: var(--text-colour);
            background-color: var(--secondary-colour);
            border-radius: 2.5px;
            padding: 0.5rem;
            list-style-type: none;
            li {
              color: var(--action-colour);
              list-style-type: circle;
              margin-left: 1rem;
            }
          }
        }

        .title {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;
          position: relative;
          cursor: pointer;
          z-index: 10;
          top: 0;
          h3 {
            margin: 0;
            padding: 0;
            text-align: center;
            font-size: 1.5rem;
            font-weight: bolder;
            line-height: 1.5rem;
          }
        }
        .amount {
          font-size: 2rem;
          font-weight: bolder;
          line-height: 2rem;
        }
      }
      .center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    .section-one {
      grid-area: 1 / 1 / 2 / 2;
    }
    .section-two {
      grid-area: 1 / 2 / 2 / 3;
    }
    .section-three {
      grid-area: 2 / 1 / 4 / 3;
    }
    .section-four {
      grid-area: 1 / 3 / 4 / 4;
    }
    .section-five {
      grid-area: 1 / 4 / 4 / 5;
      // #text {

      // }
    }
  }
}

.store-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  .main {
    flex: 1 1;
    height: 100vh;
    overflow-y: scroll;
  }
}

@media only screen and (orientation: portrait) {
  .dashboard-parent {
    background-color: var(--secondary-colour);
    height: 100vh;
    width: 100vw;
    margin-bottom: 5rem;
    margin-left: 0;
    .tabs {
      display: none;
    }
    .inner {
      .title {
        height: 70%;
      }
    }
    .dashboard-grid {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(6, 1fr);
      .section-1 {
        grid-area: 1 / 1 / 2 / 2;
      }
      .section-two {
        grid-area: 1 / 2 / 2 / 3;
      }
      .section-three {
        grid-area: 2 / 1 / 4 / 3;
      }
      .section-four {
        grid-area: 4 / 1 / 7 / 2;
      }
      .section-five {
        grid-area: 4 / 2 / 7 / 3;
      }
    }
  }
}
</style>
