<template>
  <div class="edit-container">
    <font-awesome-icon icon="pen-to-square" class="icon" />
  </div>
</template>

<script>
export default {
  name: "EditDash",
  data() {
    return {
      // Your component's data goes here
    };
  },
  methods: {
    // Your component's methods go here
  },
  mounted() {
    // Code to run when the component is mounted goes here
  },
};
</script>

<style scoped>
.edit-container {
  position: fixed;
  color: var(--text-colour);
  .icon {
  font-size: 0.65rem;
  font-weight: lighter;
  }
  .icon:hover {
    cursor: pointer;
  }
}
</style>
