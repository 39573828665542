<template>
  <div class="container">
    <ul class="settings-container">
      <li v-for="(name, s) in settings" :key="s" class="setting-box">
        <p>{{ formatSettingName(s) }}</p>
        <Toggle class="toggle" onLabel="On" offLabel="Off" v-model="settings[s]" />
      </li>
    </ul>
  </div>
</template>

<script>
import Toggle from "@vueform/toggle";
import { mapGetters } from "vuex";

export default {
  name: "DirectSettings",
  components: {
    Toggle,
  },
  data() {
    return {
      currentSite: this.site,
      // siteSettings: this.$store.getters.settingsViaSite(this.site),
      settings: {},
    };
  },

  props: {
    site: {
      type: String,
      required: true,
    },
  },

  mounted() {
    this.filteredSettings();
    console.log(this.settings);
  },

  computed: {
    ...mapGetters({
      settingsViaSite: "settingsViaSite",
    }),
  },

  methods: {
    filteredSettings() {
      let s = this.settingsViaSite(this.site).filter((setting) => typeof setting[1] === "boolean");
      s.map((i) => (this.settings[i[0]] = i[1]));
      return this.settings;
    },

    formatSettingName(setting) {
      console.log("setting is", setting);
      if (typeof setting == "string" && setting.includes("_")) {
        setting = setting.replace(/_/g, " ");
      } else if (typeof setting == "string" && setting.match(/([A-Z])/g)) {
        setting = setting.replace(/([A-Z])/g, " $1");
      } 
      return setting + "?";
    },
  },
  watch: {},
};
</script>

<style src="@vueform/toggle/themes/default.css"></style>
<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 95%;
  padding: 0 1rem;
}

.settings-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  padding: 1rem;
  margin: 0;
  background: var(--background-fade);
  border-radius: 0.5rem;
  box-shadow: var(--box-shadow);
  width: 100%;
}

.setting-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 10rem;
  height: 5rem;
  padding: 0.25rem 0.5rem;
  border: none;
  box-shadow: var(--box-shadow);
  border-radius: 5px;
  margin: 0.5rem;
  background-color: var(--primary-colour);
  p {
    text-wrap: nowrap;
    margin: 0.5rem;
    text-transform: capitalize;
  }
}

.toggle {
  display: inline-flex;
  --toggle-bg-on: #3bafda;
  --toggle-border: 2px;
  --toggle-height: 20px;
  --toggle-width: 90px;
  --toggle-font-size: 1rem;
  outline-color: #3bafda;
  outline: 0;
  border-color: #3bafda;
  border-left-width: 1px;
  border-right-width: 1px;
  .toggle-handle {
    height: 30px !important;
    width: 30px !important;
  }
}
@media only screen and (orientation: portrait) {
  .setting-box {
    width: 40%;
  }
}
</style>
