<template>
  <teleport to="body">
    <div class="modal" v-if="showModal" @click.self.prevent="showModal != showModal">
      <div class="wrapper">
        <font-awesome-icon icon="times" class="exit" @click="showModal = false" />
        <section class="content">
          <h3 v-if="newStation">Create New POS Station</h3>
          <h3 v-else>Update POS Station</h3>
          <CodeLogIn :station="station.name" :site="station.site" />
          <form @submit.prevent.stop="submit">
            <div v-if="'active' in station || !newStation" class="status">
              <font-awesome-icon icon="wifi" :class="(station.active ? 'active' : 'discon') + ' icon'"></font-awesome-icon>
              <div v-if="station.active">Active</div>
              <div v-else>Disconnected</div>
            </div>

            <div class="info">
              <h4>Station Name:</h4>
              <input type="text" v-model="openStation.name" v-if="newStation" placeholder="Station Name..." required />
              <label>{{ station.name }}</label>
              <h4>Eftpos Settings:</h4>
              <label for="ip">IP Address:</label>
              <input type="text" name="ip" v-model="openStation.eftpos.ip" placeholder="IP Address" required />
              <label for="port">Port:</label>
              <input type="text" name="port" v-model="openStation.eftpos.port" placeholder="Port" required />
            </div>
            <div class="action-btns">
              <button v-if="newStation" class="button is-primary">
                <div @click="createStation">Create</div>
              </button>
              <button v-else class="button is-primary">
                <div @click="updateStation">Update</div>
              </button>
              <button class="button delete" v-if="!newStation" @click="deleteStation">
                <div>Delete</div>
              </button>
            </div>
          </form>
        </section>
      </div>
    </div>
  </teleport>
</template>
<script>
import { api } from "@/helpers/api";
import { mapGetters } from "vuex";
import CodeLogIn from "@/components/Settings/CodeLogIn.vue";

export default {
  name: "POSStationModal",
  props: ["open", "station", "newStation"],
  emits: ["update"],
  components: {
    CodeLogIn,
  },
  data() {
    return {
      showModal: false,
      openStation: null,
    };
  },
  computed: {
    ...mapGetters({
      channels: "channels",
      siteMap: "siteMap",
      siteSettings: "siteSettings",
    }),
  },
  watch: {
    async open() {
      if (!this.open) {
        this.showModal = false;
        return;
      }
      this.showModal = true;
      this.openStation = this.station;
    },
  },
  methods: {
    async createStation() {
      delete this.openStation.active;
      await api.post("/api/company/add_station/" + this.$store.getters.companyName + "/" + this.openStation.site, { station: this.openStation, station_type: "get_pos" });
      this.$store.dispatch("initialSiteMap");
    },
    async updateStation() {
      delete this.openStation.active;
      this.$emit("update", "update", "pos", this.openStation);
    },
    async deleteStation() {
      this.$emit("update", "delete", "pos", this.openStation);
    },
  },
};
</script>
<style scoped lang="scss">
@import "public/stylesheets/wrapper";

h3 {
  color: var(--text-colour);
  text-align: center;
  margin: 0.5rem;
}

.active {
  color: var(--action-colour);
}

.discon {
  color: var(--warning-colour);
}

.delete {
  background: var(--warning-colour);
  color: var(--text-colour);
}
form {
  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1rem;
    margin: 1rem;
    h4 {
      color: var(--text-colour);
      padding: 0;
      margin: 0;
    }
    input {
      margin: 0 !important;
      border-radius: 0.25rem;
      width: 95%;
      border: none;
      padding: 0.5rem;
    }
    label {
      color: var(--action-colour);
    }
  }
  .action-btns {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 0 auto;
  }
}

.status {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin: 1rem;
  color: var(--text-colour);
}
</style>
