<template>
    <div class="parent">
        <div>
            <Nav/>
        </div>
        <div class="content">
            <div class="hero">
                <h1>What's Included</h1>
            </div>
            <div class="content-body">
                <div class="content-box">
                    <div class="content-box-image">
                        <img src="../../../public/backgrounds/test-pos.jpg" alt="">
                    </div>
                    <div class="content-box-text">
                        <h2>Point of Sale</h2>
                        <p>Our clean, easy to use system allows business's to focus more on the customer.</p>
                    </div>
                </div>
                <div class="content-box">
                    <div class="content-box-image">
                        <img src="../../../public/backgrounds/test-kds.jpeg" alt="">
                    </div>
                    <div class="content-box-text">
                        <h2>Kitchen Display</h2>
                        <p>Up to date ordering display, perfect for any type of kitchen setup. With live updates straight from the till, your kitchen will never fall behind. </p>
                    </div>
                </div>
                <div class="content-box">
                    <div class="content-box-image">
                        <img src="../../../public/backgrounds/test-reports.jpg" alt="">
                    </div>
                    <div class="content-box-text">
                        <h2>Reporting</h2>
                        <p>Get the latest live numbers from your business, along with trends and best sellers.</p>
                    </div>
                </div>
                <div class="content-box">
                    <div class="content-box-image">
                        <img src="../../../public/backgrounds/test-ordering.jpeg" alt="">
                    </div>
                    <div class="content-box-text">
                        <h2>Online Ordering</h2>
                        <p>Allow the customer to order from where they are, using a similar version of the Point of Sale they can take the ordering into there own hands.</p>
                    </div>
                </div>


            </div>

        </div>
    </div>
    
    <Footer />
    
    </template>
    
    <script>
    import Nav from '@/components/Landing/Nav.vue'
    import Footer from '@/components/Landing/Footer.vue'
    
    export default {
      data () {
        return {
    
        }
      },
      components:{
        Nav, Footer
      },
    }
    </script>
    
    <style scoped lang="scss">
    .parent{
        .content{
            padding: 5rem 0rem;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 2rem;
            .hero{
                // padding-top: 5rem;
                // background-image: linear-gradient(to top, rgba(206, 219, 242,0), rgba(206, 219, 242,1));
                // background-color: rgb(206, 219, 242);
                width: 100%;
                margin: 0 auto;
                // padding: 2rem;
                h1{
                    font-size: 4rem;
                    color: var(--text-colour)
                }
                p{
                    font-size: 1.5rem;
                    margin: 2rem 9rem;
                    color: var(--text-colour);
                }
            }

            .content-body{
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-direction: column;
                gap: 3rem;
                .content-box{
                    width: 60rem;
                    height: 30rem;
                    border-radius: 2rem;
                    // border: 4px solid #5097f4;
                    background-color: var(--secondary-colour);
                    box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    overflow: hidden;
                    gap:2rem;
                    .content-box-image{
                        // border-right: solid 1rem var(--text-colour);
                        overflow: hidden;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 100%;
                        width: 30rem;

                        img{
                            height: 40rem;
                            object-fit:cover;
                            display: block;
                        } 
                    }
                    .content-box-text{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-end;
                        gap: 2rem;
                        width: 30rem;
                        margin-right: 2rem;
                        h2{
                            font-size: 3rem;
                            color: var(--text-colour);
                            margin: 0;
                        }
                        p{
                            font-size: 1.5rem;
                            color: var(--text-colour);
                            text-align: right;
                        }
                    }
                    &:nth-child(even){
                        flex-direction: row-reverse;
                        .content-box-text{
                            align-items: flex-start;
                            margin-left: 2rem;
                            margin-right: 0;
                            p{
                                text-align: left;
                            }
                        }
                    }
                }
            }
        }
    }
    
    @media only screen and (orientation: portrait) {

    }
    
    
    
    
    </style>