<template>
  <div class="container">
    <slot/>
    <footer>
      &copy;2024 Beamy Limited
      | <a href="/pricing">Pricing</a>
      | <a href="/privacy">Privacy Policy</a>
      | <a href="/tos">Terms of Service</a>
      | <a href="/about">About</a>
      | <a href="/support">Support</a>
    </footer>
  </div>
</template>

<style scoped lang="scss">

div.container {
  min-width: 100vw;
  padding: 2rem;
  min-height: 100vh;
  background-color: var(--primary-colour);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

footer {
  bottom: 3em;
  font-size: 1em;
  color: var(--text-colour);
  left: 0px;
  right: 0px;
  margin: 0px auto;
  padding-top: 20px;
  text-align: center;

  a { color: var(--text-colour); }
}

@media screen and (max-width: 720px) {

  div.container {
    align-items: flex-start;
  }

  footer {
    font-size: 0.8em;
  }
}


</style>