<template>
  <nav class="nav" v-if="available_reports">
    <ul :class="'nav__list' + (active ? ' active' : '')" @click="active = !active">
      <li class="nav__item" :key="idx" v-for="(report_key, idx) in available_reports">
        <router-link :to="'/reports/' + report_key">
          {{ reports[report_key].label }}
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import Reports from "@/helpers/reports";

export default {
  name: "Submenu",
  emits: ["showDateMenu"],
  data() {
    return {
      active: false,
      channel: null,
      authenticated: window.localStorage.getItem("token"),
    };
  },
  mounted() {
    if (this.$route.path.match(/^\/reports/)) {
      this.$emit("showDateMenu");
    }
  },
  computed: {
    reports() {
      return Reports.reports;
    },
    available_reports() {
      const keys = Object.keys(Reports.reports);
      return keys;
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
a {
  display: block;
  cursor: pointer;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.nav {
  &__list {
    background: white;
    padding: 0rem;
    flex: 1 1;
    width: 12rem;
    height: 100vh;
    background-color: var(--secondary-colour);
    position: fixed;
    left: 5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
  }
  &__item {
    list-style: none;
    font-size: 0.8rem;
    text-align: right;
    transition: all 200ms ease-in;
    padding: 0.5rem 1rem 0.5rem 1rem;
    transition: 500ms ease-in-out;
    &:hover {
      background-color: var(--banner-colour);
    }

    a {
      color: var(--text-colour);
      text-decoration: none;

      &:hover {
        color: var(--action-colour);
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .nav {
    &__list {
      flex-direction: row;
      width: 100vw;
      height: 3rem;
      bottom: 5rem;
      left: 0rem;
      overflow-y: hidden;
      align-items: center;
      justify-content: space-around;
      padding: 0 1rem;
    }

    &__item {
      text-align: center;
      font-size: 1rem;
      padding-top: 0.8rem;
      padding-bottom: 1rem;
    }

    &__list.active {
      top: 0px;
      height: calc(100vh - 5rem);
      overflow-y: auto;
    }
  }
}
</style>
