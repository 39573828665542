<template>
  <Authenticated>
    <Submenu v-if="$route.path.match(/^\/reports/)" @showDateMenu="showDateMenu = !showDateMenu" />
    <Datemenu v-if="$route.path.match(/^\/reports/) && showDateMenu" />
    <div>
      <section class="container">
        <h1>Billing</h1>
        <section class="card">
          <div class="box">
            <h3>Stations Company wide: {{ totalStations }}</h3>
            <div v-for="site in siteTotal" :key="site">
              <h3 class="blue title">{{ site.result }}</h3>
              <p>{{ site.siteClass }} ${{ site.classCost }}</p>
            </div>
            <h3 class="blue">Monthly Total: ${{ monthlyTotal }}</h3>
          </div>
          <section class="box">
            <button class="press" @click.stop.prevent="openPaymentMethodModal">Add Payment Method</button>
          </section>
        </section>
      </section>
      <section class="container">
        <h2>Sites</h2>
        <div class="card">
          <div v-for="(site, idx) in siteMap" :key="site + idx" class="box">
            <div>
              <h3 class="title">{{ site.site }}</h3>
              <p class="blue" v-if="site.stations.kds.length">KDS: {{ site.stations.kds.length }}</p>
              <p class="blue" v-else>KDS: 0</p>
              <p class="blue" v-if="site.stations.pos.length">POS: {{ site.stations.pos.length }}</p>
              <p class="blue" v-else>POS: 0</p>
              <p class="blue">Total Stations: {{ site.stations.kds.length + site.stations.pos.length }}</p>
              <p class="blue" v-if="cogsEnabled"></p>
              <div>
                <h3 v-if="site.stations.kds.length + site.stations.pos.length <= 3 && site.stations.kds.length + site.stations.pos.length > 1">Omega class POS at ${{ omega }} a month.</h3>
                <h3 v-if="site.stations.kds.length + site.stations.pos.length <= 6 && site.stations.kds.length + site.stations.pos.length > 3">Beta class POS at ${{ beta }} a month.</h3>
                <h3 v-if="site.stations.kds.length + site.stations.pos.length > 9">Alpha class POS at ${{ alpha }} a month.</h3>
                <h3 v-else-if="site.stations.kds.length + site.stations.pos.length == 0">This site has no stations yet...</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <PaymentMethodModal :open="showPaymentModal" />
  </Authenticated>
</template>

<script>
import Submenu from "@/components/_layouts/Submenu";
import Datemenu from "@/components/_layouts/Datemenu";
import PaymentMethodModal from "./PaymentMethodModal.vue";
import Authenticated from "@/components/_layouts/Authenticated";
import createdMixin from "@/components/_mixins/createdMixin";
import { mapGetters } from "vuex";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import dayOfYear from "dayjs/plugin/dayOfYear";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(dayOfYear);
dayjs.extend(timezone);

dayjs.tz.setDefault("Pacific/Auckland");

export default {
  name: "Billing",
  mixins: [createdMixin],
  components: { Authenticated, Submenu, Datemenu, PaymentMethodModal },
  emits: ["handleMenu"],
  async mounted() {},
  data() {
    return {
      channel: null,
      omega: 60,
      beta: 90,
      alpha: 140,
      showPaymentModal: false,
    };
  },
  computed: {
    ...mapGetters({
      sites: "sites",
      siteMap: "siteMap",
      cogsEnabled: "cogsEnabled",
    }),

    totalStations() {
      let total = 0;
      this.siteMap.forEach((site) => {
        total += site.stations.kds.length + site.stations.pos.length;
      });
      return total;
    },

    monthlyTotal() {
      let total = 0;
      this.siteMap.forEach((site) => {
        let stationsInSite = site.stations.kds.length + site.stations.pos.length;
        if (stationsInSite <= 3 && stationsInSite > 0) {
          total += this.omega;
        } else if (stationsInSite <= 6 && stationsInSite > 3) {
          total += this.beta;
        } else if (stationsInSite >= 9) {
          total += this.alpha;
        }
      });
      return total;
    },

    siteTotal() {
      let siteResult = [];
      this.siteMap.forEach((site) => {
        let stationsInSite = site.stations.kds.length + site.stations.pos.length;
        let siteClass = "";
        let classCost = 0;
        if (stationsInSite <= 3 && stationsInSite > 0) {
          siteClass = "Omega class";
          classCost = this.omega;
        } else if (stationsInSite <= 6 && stationsInSite > 3) {
          siteClass = "Beta class";
          classCost = this.beta;
        } else if (stationsInSite >= 9) {
          siteClass = "Alpha class";
          classCost = this.alpha;
        }
        let result = site.site + ": " + stationsInSite;
        siteResult.push({ result, siteClass, classCost });
      });
      return siteResult;
    },
  },
  methods: {
    openPaymentMethodModal() {
      this.showPaymentModal = !this.showPaymentModal;
    },
  },
};
</script>

<style scoped lang="scss">

@import "public/stylesheets/layout";
@import "public/stylesheets/wrapper";
@import "public/stylesheets/actions";
h1 {
  margin: 1rem;
}
.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  padding-top: 1rem;
  h2,
  h1 {
    flex: 1 0 100%;
  }
  .card {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: 10px;
    background: var(--background-fade);
    box-shadow: var(--box-shadow);
    margin: 1rem;
    padding: 0.5rem;
    width: 100%;
    height: 100%;
    .blue {
      color: var(--action-colour);
    }
    .title {
      text-transform: capitalize;
    }
  }

  .box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 10rem;
    width: 20rem;
    height: 100%;
    border-radius: 5px;
    margin: 0.5rem;
    padding: 0.5rem;
    box-shadow: var(--box-shadow);
    background-color: var(--primary-colour);
    p {
      margin: 0.5rem;
      text-transform: capitalize;
    }
    h3 {
      margin: 0.5rem;
    }
    .press {
      background-color: var(--action-colour);
      box-shadow: var(--box-shadow);
      color: var(--secondary-text-colour);
      width: calc(100% - 2rem);
      &:active {
        scale: 0.95;
      }
    }
  }
}
@media only screen and (orientation: portrait) {
  .container {
    flex-direction: column;
    width: 90%;
    h2 {
      width: 50%;
    }
    .card {
      flex-direction: column;
      width: 100%;
    }
  }
}
</style>
