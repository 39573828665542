<template>
  <teleport to="body">
    <div class="modal" v-if="showModal" @click.self.prevent="showModal != showModal">
      <div class="content">
        <font-awesome-icon icon="times" class="exit" @click="showModal = false" @emit="timeCheck" />
        <Timezone />
      </div>
    </div>
  </teleport>
</template>

<script>
import Timezone from "@/components/Settings/Timezone.vue";

export default {
  name: "TZModal",
  components: {
    Timezone,
  },
  data() {
    return {
      showModal: false,
    };
  },
  props: ["open"],
  methods: {
    // Your component's methods go here
  },

  watch: {
    async open() {
      this.showModal = true;
    },
  },
};
</script>

<style scoped lang="scss">
@import "public/stylesheets/wrapper";
.modal {
  margin: 0;

  .content {
    width: 50%;
  }
}
</style>
