<template>
  <div>
    <h3>Timezone: {{ timezone }}</h3>
    <input name="timezone" type="hidden" v-model="tz_name" />
    <l-map ref="map" style="height: 70vh;" v-model:zoom="zoom" :center="center" class="map">
      <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" layer-type="base" name="OpenStreetMap"> </l-tile-layer>
      <l-geo-json :geojson="geojson" :options="geojsonOptions" />
    </l-map>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { api } from "../../helpers/api";
import { LMap, LTileLayer, LGeoJson } from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";
import timezones from "../../../public/timezones.json";
export default {
  name: "Timezone",
  components: {
    LMap,
    LTileLayer,
    LGeoJson,
  },
  data() {
    return {
      zoom: 2,
      center: [0, 0],
      tz_name: null,
      geojson: null,
      geojsonOptions: { onEachFeature: this.onEachFeature, style: { color: "#000", weight: 1, opacity: 0.3 } },
    };
  },

  computed: {
    ...mapGetters({
      timezone: "timezone",
      companyName: "companyName",
    }),
  },

  watch: {
    tz_name: function(val) {
      this.$store.commit("timezoneMutation", val);
      api.save_company_settings(this.companyName, "timezone", val);
    },
  },

  async created() {
    this.geojson = timezones;
  },
  methods: {
    onEachFeature(feature, layer) {
      //console.log(feature);
      layer.on({ click: this.featureClicked });
    },
    featureClicked(e) {
      var tz = JSON.parse(JSON.stringify(e.target.feature));
      console.log(e);
      this.tz_name = tz.properties.tz_name1st;
    },
  },
  mounted() {
    // Code to run when the component is mounted goes here
  },
};
</script>

<style>
.map {
  border-radius: 5px;
}
</style>
