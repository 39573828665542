<template>
  <teleport to="body">
    <div class="modal" v-if="showModal" @click.self.prevent="showModal != showModal">
      <div class="wrapper">
        <div class="content">
          <font-awesome-icon icon="times" class="exit" @click="showModal = false" />
          <h3 v-if="newStation">Create New KDS Station</h3>
          <h3 v-else>Update KDS Station</h3>
          <CodeLogIn :station="station.name" :site="station.site" />
          <form @submit.prevent.stop="submit">
            <div class="name">
              <h4>Station Name:</h4>
              <input type="text" v-model="openStation.name" v-if="newStation" placeholder="Station Name..." required />
              <label v-else>{{ station.name }}</label>
            </div>
            <div class="watches">
              <h4>Station Watches:</h4>
              <ul>
                <div v-for="cat in categories.rows" :key="cat.id">
                  <input type="checkbox" v-model="openStation.categories" :value="cat.key.name" />
                  <label for="test">{{ cat.key.name }}</label>
                </div>
              </ul>
            </div>
            <div class="action-btns">
              <button v-if="newStation" class="button is-primary" @click="createStation">
                <div>Create</div>
              </button>
              <button v-else class="button is-primary" @click="updateStation">
                <div>Update</div>
              </button>
              <button class="button delete" v-if="!newStation" @click="deleteStation">
                <div>Delete</div>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </teleport>
</template>
<script>
import { api } from "@/helpers/api";
import { mapGetters } from "vuex";
import CodeLogIn from "@/components/Settings/CodeLogIn.vue";

export default {
  name: "KDSStationModal",
  props: ["open", "station", "newStation"],
  emits: ["update"],
  components: {
    CodeLogIn,
  },
  data() {
    return {
      showModal: false,
      categories: [],
      openStations: null,
    };
  },
  computed: {
    ...mapGetters({
      channels: "channels",
      siteMap: "siteMap",
      siteSettings: "siteSettings",
    }),
  },
  watch: {
    async open() {
      if (!this.open) {
        this.showModal = false;
        return;
      }
      this.showModal = true;
      this.openStation = this.station;

      this.categories = await api.get("/api/categories/get/" + window.localStorage.getItem("company") + "/" + this.station.site, "?include_docs=false");
    },
  },
  methods: {
    async createStation() {
      delete this.openStation.active;

      await api.post("/api/company/add_station/" + this.$store.getters.companyName + "/" + this.openStation.site, { station: this.openStation, station_type: "get_kds" });
      this.$store.dispatch("initialSiteMap");
    },
    async updateStation() {
      delete this.openStation.active;
      this.$emit("update", "update", "kds", this.openStation);
    },
    async deleteStation() {
      this.$emit("update", "delete", "kds", this.openStation);
    },
  },
};
</script>
<style scoped lang="scss">
@import "public/stylesheets/wrapper";

h3 {
  color: var(--text-colour);
  text-align: center;
  margin: 0.5rem;
}

.active {
  color: var(--action-colour);
}

.discon {
  color: var(--warning-colour);
}

.delete {
  background: var(--warning-colour);
  color: var(--text-colour);
}
form {
  .name {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    margin: 1rem;
    h4,
    input {
      margin: 0 !important;
      border-radius: 0.25rem;
    }
  }
  .action-btns {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 0 auto;
  }
}

.status {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin: 1rem;
}
</style>
