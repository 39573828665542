<template>
  <div class="landscape">
    <nav class="nav" v-if="authenticated">
      <ul class="nav__list">
        <li class="nav__item">
          <router-link to="/dashboard">
            <font-awesome-icon icon="fa-house" size="2xl" />
            <p class="nav__itemlabel">Dashboard</p>
          </router-link>
        </li>
        <li class="nav__item">
          <router-link to="/reports">
            <font-awesome-icon icon="chart-pie" size="2xl" />
            <p class="nav__itemlabel">Sales</p>
          </router-link>
        </li>
        <li class="nav__item">
          <router-link to="/billing">
            <font-awesome-icon icon="chart-line" size="2xl" />
            <p class="nav__itemlabel">Billing</p>
          </router-link>
        </li>
        <li v-if="cogsEnabled" class="nav__item">
          <router-link to="/cogs">
            <font-awesome-icon icon="fa-gears" size="2xl" />
            <p class="nav__itemlabel">COGS</p>
          </router-link>
        </li>
        <li class="nav__item grow">
          <router-link to="/settings">
            <font-awesome-icon icon="fa-wrench" size="2xl" />
            <p class="nav__itemlabel">Settings</p>
          </router-link>
        </li>
        <li v-if="authenticated" class="nav__item">
          <a href="#" @click="logout">
            <font-awesome-icon icon="right-from-bracket" size="2xl" />
            <p class="nav__itemlabel">Logout</p>
          </a>
        </li>
        <!--      <ul v-if="false && available_reports">-->
        <!--      <li :key="idx" v-for="(key, idx) in available_reports">-->
        <!--        {{ reports[key].label }}-->
        <!--      </li>-->
        <!--     </ul>-->
      </ul>
    </nav>
  </div>

  <div class="portrait">
    <font-awesome-icon v-if="!showMenu" icon="chevron-right" @click="showMenu = !showMenu" class="right-chev" />
    <transition name="slide">
      <font-awesome-icon v-if="showMenu" icon="chevron-left" @click="showMenu = !showMenu" class="left-chev" />
    </transition>
    <transition name="slide">
      <nav v-if="authenticated && showMenu">
        <ul class="nav__list">
          <li class="nav__item" @click="showMenu = !showMenu">
            <router-link to="/dashboard">
              <font-awesome-icon icon="fa-house" size="2xl" />
              <p class="nav__itemlabel">Dashboard</p>
            </router-link>
          </li>
          <li class="nav__item" @click="showMenu = !showMenu">
            <router-link to="/reports">
              <font-awesome-icon icon="chart-pie" size="xl" />
              <p class="nav__itemlabel">Sales</p>
            </router-link>
          </li>
          <li class="nav__item" @click="showMenu = !showMenu">
            <router-link to="/billing">
              <font-awesome-icon icon="chart-line" size="xl" />
              <p class="nav__itemlabel">Billing</p>
            </router-link>
          </li>
          <li v-if="cogsEnabled" li class="nav__item" @click="showMenu = !showMenu">
            <router-link to="/cogs">
              <font-awesome-icon icon="fa-gears" size="xl" />
              <p class="nav__itemlabel">COGS</p>
            </router-link>
          </li>
          <li class="nav__item grow" @click="showMenu = !showMenu">
            <router-link to="/settings">
              <font-awesome-icon icon="fa-wrench" size="xl" />
              <p class="nav__itemlabel">Settings</p>
            </router-link>
          </li>
          <li v-if="authenticated" class="nav__item">
            <a href="#" @click="logout">
              <font-awesome-icon icon="right-from-bracket" size="xl" />
              <p class="nav__itemlabel">Logout</p>
            </a>
          </li>
        </ul>
      </nav>
    </transition>
  </div>

  <!-- </div> -->
</template>

<script>
import Reports from "@/helpers/reports";
import { mapGetters } from "vuex";

export default {
  name: "Menu",
  data() {
    return {
      channel: null,
      authenticated: window.localStorage.getItem("token"),
      showMenu: false,
    };
  },
  computed: {
    ...mapGetters({
      cogsEnabled: "cogsEnabled",
    }),
    reports() {
      return Reports.reports;
    },
    available_reports() {
      const keys = Object.keys(Reports.reports);
      return keys;
    },
  },
  methods: {
    async logout() {
      this.socket = this.$store.getters.socket;

      this.channels = this.$store.getters.channels;

      this.channels.forEach((channel) => {
        channel.leave();
      });

      await this.socket.disconnect();
      this.$store.dispatch("wsListenersUp");

      this.$root.token = null;
      await window.localStorage.clear();

      this.$router.go({ path: "/login" });
    },
    showPresentStations() {
      console.log(this.$store.getters.posStations);
      console.log(this.$store.getters.kdsStations);
    },
  },
};
</script>

<style scoped lang="scss">
a {
  display: block;
  cursor: pointer;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.portrait {
  display: none;
}
.nav {
  &__list {
    background: white;
    box-shadow: var(--box-shadow);   padding: 1rem 0;
    width: 5rem;
    height: 100vh;
    display: flex;
    background-color: var(--primary-colour);
    position: fixed;
    z-index: 999;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
  }
  &__item {
    list-style: none;
    font-size: 0.8rem;
    text-align: center;
    width: calc(100% - 5px);
    margin: 0px auto;
    transition: all 200ms ease-in-out;
    margin-bottom: 0.5rem;

    svg {
      transition: all 200ms ease-in-out;
    }
  }
  .grow {
    flex-grow: 1;
  }

  a {
    color: var(--text-colour);
    text-decoration: none;

    &:hover {
      color: var(--action-colour);
    }
  }
}

@media (orientation: portrait) {
  .landscape {
    display: none;
  }
  .portrait {
    display: block;
    position: fixed;
    z-index: 1000;
    top: 0;
    .slide-enter-active,
    .slide-leave-active {
      transition: transform 500ms ease;
      transform: translateX(0);
    }

    .slide-enter-from,
    .slide-leave-to {
      transform: translateX(-100%);
    }
    .left-chev {
      padding: 1rem;
      width: 3rem;
      background-color: var(--primary-colour);
      box-shadow: var(--box-shadow);
      // transition: all 1000ms ease-in-out;
    }

    .right-chev {
      padding: 1rem;
      width: 3rem;
      transition: all 1000ms ease-in-out;
    }

    svg {
      padding: 1rem;
    }

    .nav {
      display: none;
    }

    .grow {
    flex-grow: 1;
  }

    .nav__list {
      z-index: 10000;
      height: 95vh;
      // top: 0;
      gap: 1rem;
      .chevron-left {
        padding: 1px;
      }
    }

    &__list {
      flex-direction: row;
      width: 100vw;
      height: 5rem;
      align-items: center;
      justify-content: space-around;
      // padding: 0 1rem;
      z-index: 10;
    }
  }
}
</style>
