<template >
    <ul class="grid wrapper">
          <li class="modifierBox" @click="theme('os')">
            <p>OS Default</p>
            <input checked type="radio" id="os" class="toggle radio " name="os"  />
          </li>
          <li class="modifierBox" @click="theme('light')">
            <p>Light Mode</p>
            <input type="radio" id="light" class="toggle radio" name="os"  />
          </li>
          <li class="modifierBox" @click="theme('dark')">
            <p>Dark Mode</p>
            <input type="radio" id="dark" class="toggle radio" name="os" />
          </li>
        </ul>
</template>
<script>
export default {
    name: 'Theme', 
    emits: ['themeChange'],
    data() {
    return {
      darkMode: false,
      lightMode: false,
      osDefault: true,
    }
  },
    mounted(){
    
    if(document.documentElement.classList.contains('dark-theme')){
      document.getElementById('dark').checked = true;
    }
    if(document.documentElement.classList.contains('light-theme')){
      document.getElementById('light').checked = true;
    }
  },
  methods: {
    theme(name){
      document.documentElement.classList.remove('light-theme')
      document.documentElement.classList.remove('dark-theme')

      if (name !== 'os') {
        document.documentElement.classList.add(`${name}-theme`)
        document.getElementById(`${name}`).checked = true;
      }else{
        document.getElementById('os').checked = true;
      }
        this.$emit('themeChange', name)
    },
}
    
}
</script>
<style scoped lang="scss">
@import "public/stylesheets/layout";
@import "public/stylesheets/wrapper";
@import "public/stylesheets/actions";
    
.toggle {
  --toggle-width: 12rem;
  --toggle-border-off: black;
  --toggle-border-on: black;
  --toggle-bg-on: var(--action-colour);
  --toggle-border: 2px;
  --toggle-height: 48px;
  --toggle-font-size: 1rem;
  outline-color: green;
  outline: 0;
  border-color: var(--action-colour);
  border-left-width: 1px;
  border-right-width: 1px;
}
.radio{
  accent-color: var(--action-colour);
}

@media only screen and (orientation: portrait) {
  .toggle {
  --toggle-width: 7rem;
  }

}

</style>