<template>
  <div class="parent">
    <div>
      <Nav />
    </div>
    <div class="content">
      <div class="hero">
        <h1>Pricing</h1>
        <div class="sub-content">
          <p>We aim to be as low cost as possible, <br />no matter how big or small your business is.</p>
        </div>
      </div>
      <div class="content-body">
        <div class="content-box">
          <h1>$15</h1>
          <p>per <br />Point of Sale station <br />(POS)</p>
        </div>

        <div class="content-box">
          <h1>$10</h1>
          <p>per <br />Kitchen display Station (KDS)</p>
        </div>

        <div class="content-box">
          <h1>$45</h1>
          <p>per <br />Customer Web ordering</p>
        </div>
      </div>
      <div class="hero">
        <h1>Bundles</h1>
        <div class="sub-content">
          <p>Choose one of our Packages to suit you and the size of your business.</p>
        </div>
      </div>
      <div class="content-body">
        <div class="bundle-box">
          <h1>Small Business</h1>
          <h2>$50</h2>
          <p>POS station & Web Ordering</p>
        </div>
        <div class="bundle-box" id="most-popular">
          <h1>Medium Business</h1>
          <h2>$65</h2>
          <p>Two POS station & Two KDS & Web Ordering</p>
        </div>
        <div class="bundle-box">
          <h1>Large Business</h1>
          <h2>$90</h2>
          <p>Unlimted POS stations, Unlimted KDS's & Multi Site Web Ordering</p>
        </div>
      </div>
    </div>
  </div>

  <Footer />
</template>

<script>
import Nav from "@/components/Landing/Nav.vue";
import Footer from "@/components/Landing/Footer.vue";

export default {
  data() {
    return {};
  },
  components: {
    Nav,
    Footer,
  },
};
</script>

<style scoped lang="scss">
.parent {
  .content {
    padding: 5rem 0rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;

    .hero {
      padding-top: 5rem;
      // background-image: linear-gradient(to top, rgba(206, 219, 242,0), rgba(206, 219, 242,1));
      // background-color: rgb(206, 219, 242);
      width: 100%;
      margin: 0 auto;
      // padding: 2rem;
      h1 {
        font-size: 4rem;
        color: var(--text-colour);
      }
      p {
        font-size: 1.5rem;
        margin: 2rem 9rem;
        color: var(--text-colour);
      }
    }
    .content-body {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      gap: 3rem;

      .sub-content {
        p {
          color: var(--text-colour);
          font-size: x-large;
        }
      }

      .content-box {
        height: 25rem;
        width: 20rem;
        background-color: var(--secondary-colour);
        border-radius: 1rem;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h1 {
          font-size: 8rem;
          color: white;
          margin: 0 auto;
        }

        p {
          color: white;
          font-size: 1.5rem;
          margin: 1rem 1rem;
        }

        .content-box-image {
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 1rem 1rem 0rem 0rem;
          img {
            height: 15rem;
            object-fit: cover;
            display: block;
          }
        }
      }
      .bundle-box {
        height: 25rem;
        width: 20rem;
        background-color: var(--secondary-colour);
        border-radius: 1rem;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h1 {
          font-size: 2rem;
          color: white;
          margin: 0 auto;
        }
        h2 {
          font-size: 2rem;
          color: white;
          margin: 0 auto;
        }

        p {
          color: white;
          font-size: 1.5rem;
          margin: 1rem 1rem;
        }

        #most-popular {
            border: 2px solid var(--text-colour);
        }

        .content-box-image {
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 1rem 1rem 0rem 0rem;
          img {
            height: 15rem;
            object-fit: cover;
            display: block;
          }
        }
      }
    }
  }
}

@media only screen and (orientation: portrait) {
  .parent {
    .content {
      .hero {
        p {
          margin: 1rem 2rem;
        }
      }

      .content-body {
        flex-direction: column;
      }
    }
  }
}
</style>
