import App from './App.vue'
import Dashboard from './components/Dashboard/Dashboard.vue'
import Reports from './components/Reports/Reports.vue'
import Setup from './components/Setup/Setup.vue'
import Site from './components/Setup/Site.vue'
import Station from './components/Setup/Station.vue'
import Settings from './components/Settings/Settings.vue'
import Login from './components/Auth/Login.vue'
import Signup from './components/Auth/Signup.vue'
import Validate from './components/Auth/Validate.vue'
import WaitingForValidation from './components/Auth/WaitingForValidation.vue'
// import Home from './components/Landing/Home.vue'
import Pricing from './components/Landing/Pricing.vue'
import Included from './components/Landing/Included.vue'
import Billing from './components/Billing/Billing.vue'
import COGS from './components/COGS/COGS.vue'
import {createStore} from 'vuex';
import state from './store/state.js';
import token from './store/token.js';
import {createApp} from 'vue';
import  Notifications from '@kyvg/vue3-notification';

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPhone, faEdit, faPlus, faSave, faTrash, faBars, faSort, faMinusCircle, faPlusCircle, faEnvelope, faHouse, faPlug, faChartPie, faChartLine, faGears, faRightFromBracket, faMoneyBillWave, faCheck, faWifi, faTimes, faWrench, faArrowRight, faArrowLeft, faChevronRight, faChevronLeft, faLink, faPencil, faFloppyDisk, faCaretDown, faCaretRight, faPenToSquare} from "@fortawesome/free-solid-svg-icons";
library.add(faPhone, faEdit, faPlus, faSave, faTrash, faBars, faSort, faMinusCircle, faPlusCircle, faEnvelope, faHouse, faPlug, faChartPie, faChartLine, faGears, faRightFromBracket, faMoneyBillWave, faCheck, faWifi, faTimes, faWrench, faArrowRight, faArrowLeft, faChevronRight, faChevronLeft, faLink, faPencil, faFloppyDisk, faCaretDown, faCaretRight, faPenToSquare);

import {createRouter, createWebHistory} from 'vue-router'

const store = createStore({
    modules: { token: token, state: state }
})
export default store;

const router = new createRouter({
    history: createWebHistory(),
    routes: [
        {path: '/', component: Login},
        {path: '/pricing', name: 'Pricing', component: Pricing},
        {path: '/included', name: 'Included', component: Included},
        {path: '/login', name: 'Login', component: Login},
        {path: '/signup', name: 'Signup', component: Signup},
        {path: '/signup/waiting-for-validation', name: 'WaitingForValidation', component: WaitingForValidation},
        {path: '/billing', name: 'Billing', component: Billing, meta: {requiresAuth: true}},
        {path: '/dashboard', component: Dashboard, meta: {requiresAuth: true}},
        {path: '/dashboard/:site', component: Dashboard, meta: {requiresAuth: true}},
        {path: '/reports/:site', component: Reports, meta: {requiresAuth: true}},
        {path: '/reports/:site/:dataType', component: Reports, meta: {requiresAuth: true}},
        {path: '/reports', component: Reports, meta: {requiresAuth: true}},
        {path: '/setup/company', component: Setup, meta: {requiresAuth: true}},
        {path: '/setup/site', component: Site, meta: {requiresAuth: true}},
        {path: '/setup/station', component: Station, meta: {requiresAuth: true}},
	{path: '/validate/:token', name: 'Validate', component: Validate, meta: {requiresAuth: false}},
      {path: '/settings', name: 'Settings', component: Settings, meta: {requiresAuth: true}},
      {path: '/cogs', name: 'COGS', component: COGS, meta: {requiresAuth: true}},
    ]
})

const isAuthenticated = async() => {
    return store.getters.token ? true : false;
}

router.beforeEach(async (to, from, next) => {
  if (!state.state.siteMapLoaded) {
    store.dispatch("getAllData");
  }
  const authenticated = await isAuthenticated();
  if ( to.meta.requiresAuth ) {
    if ( authenticated )
      return next() 
    return next({name: 'Login'});
  }
  return next();
})

const app = createApp(App)
app.use(Notifications).use(router).use(store).component("font-awesome-icon", FontAwesomeIcon).mount('#app');
