<template>
  <div class="container">
    <Menu />

    <div id="content"><slot /></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Menu from "@/components/_layouts/Menu";

export default {
  components: {
    Menu,
  },
  name: "Authenticated",
  data() {
    return {
      showDateMenu: false,
    };
  },
  methods: {
    handleMenu() {},
  },
  computed: {
    ...mapGetters({
      loading: "loading",
    }),
  },
};
</script>

<style lang="scss">
.container {
  display: flex;
  // background-color: var(--primary-colour);
  color: var(--text-colour);
  #content {
    flex: 1 0;
    margin-left: 5rem;
  }
}

@media (orientation: portrait) {
  .container {
    width: 100%;
    #content {
      margin-left: 0;

      // margin-bottom: 5rem;
    }
  }
}
</style>
