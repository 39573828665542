import { bagelSocket } from "@/helpers/socket";
// import state from "../../main.js";

export default {
  mounted() {
    if (!this.wsListenersUp) {
      bagelSocket.setUpChannel(this);
      this.channels = this.$store.state.state.channels;
      
      this.wsListenersUp = this.$store.state.state.wsListenersUp;
      this.channels.forEach((channel) => {
        channel.on("presence_state", (presence) => {
          this.$store.dispatch("updateSiteMap", { channel: channel, presence: presence });
        });
        channel.on("presence_diff", (presence) => {
          this.$store.dispatch("updateSiteMapDiff", { channel: channel, presence: presence });
        });
      });

      this.$store.dispatch("wsListenersUp");
    }
  },
};
